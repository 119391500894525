const AuthForm = () => {
    return ( 
        <form action="">
            <input type="text" placeholder="Username" />
            <input type="password" placeholder="Password" />
            <button>Submit</button>
        </form>
     );
}
 
export default AuthForm;