import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  //Navigate,
} from "react-router-dom";
import LoadingOverlay from "./modules/core/components/LoadingOverlay";
import NavBar from "./modules/core/components/NavBar";
import HomePage from "./modules/core/pages/Home";
import LoginPage from "./modules/auth/pages/LoginPage";
//import socketIO from "socket.io-client";

const helmetContext = {};

function App() {
  //const { authenticated, loading } = useAuth();

  const loading = false;
  const authenticated = false;

  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <Router>
          <Routes>
            <Route
              exact
              path="/*"
              element={<LoadingOverlay loading={loading} />}
            />
          </Routes>
          {authenticated ? (
            <>
              <Routes>
                <Route exact path="/*" element={<NavBar />} />
              </Routes>
              <Routes>
                <Route exact path="/" element={<HomePage />} />
              </Routes>
            </>
          ) : (
            <>
              <Routes>
                <Route exact path="/auth/login" element={<LoginPage />} />
                <Route exact path="/auth/register" element={<LoginPage />} />
              </Routes>
              <Routes>
                <Route
                  exact
                  path="/*"
                  element={<Navigate to={"/auth/login"} />}
                />
              </Routes>
            </>
          )}
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
