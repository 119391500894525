import AuthForm from "../components/authForm";

const LoginPage = () => {
    return ( 
        <div className="xl:w-9/12 bg-dark-primary dark:bg-primary">
            <h1>Login Page</h1>
            <AuthForm />
        </div>
     );
}
 
export default LoginPage;